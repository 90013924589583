import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { getDirection } from "../modules/functions";



export const sliderParams = {
    doctors: {
        spaceBetween: 16,
        //cssMode: true,
        modules: [Navigation, Pagination],
        breakpoints: {
            0: {
                slidesPerView: "auto",
            },
            540: {
                slidesPerView: 2,
            },
            720: {
                slidesPerView: 3,
            },
            960: {
                slidesPerView: 4,
            },
            1140: {
                slidesPerView: 5,
            },
        },
        pagination: {
            el: ".doctors-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        },
        navigation: {
            nextEl: ".doctors-slider .slider__arrow-next",
            prevEl: ".doctors-slider .slider__arrow-prev",
        }
    },
    reviews: {
        //slidesPerView: 4,
        modules: [Navigation, Pagination],
        breakpoints: {
            0: {
                slidesPerView: 1,
                //centeredSlides: true
            },
            540: {
                spaceBetween: 25,
                slidesPerView: 2,
            },
            720: {
                spaceBetween: 25,
                slidesPerView: 2,
            },
            960: {
                spaceBetween: 25,
                slidesPerView: 3,
            },
            1140: {
                spaceBetween: 25,
                slidesPerView: 3,
            }
        },
        pagination: {
            el: ".reviews-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        },
        navigation: {
            enabled: {
                720: {
                    enabled: true,
                }
            },
            nextEl: ".reviews-slider .slider__arrow-next",
            prevEl: ".reviews-slider .slider__arrow-prev",
        }
    },
    offers: {
        spaceBetween: 25,
        direction: getDirection(),
        modules: [Navigation, Pagination],
        breakpoints: {
            0: {
                slidesPerView: "auto",
            },
            540: {
                slidesPerView: 2,
            },
            720: {
                slidesPerView: 3,
            },
            960: {
                slidesPerView: 4,
            },
            1140: {
                slidesPerView: 5,
            },
        },
        pagination: {
            el: ".offers-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        },
        navigation: {
            nextEl: ".offers-slider .slider__arrow-next",
            prevEl: ".offers-slider .slider__arrow-prev",
        }
    }
};
window.addEventListener("DOMContentLoaded", function () {
    const sliders = document.querySelectorAll(".swiper");
    if (sliders.length > 0) {
        for (const slider of sliders) {

            if (typeof slider.swiper === "undefined" && typeof slider.dataset.slider !== "undefined" && typeof sliderParams[slider.dataset.slider] !== "undefined") {
                //console.log(slider);
                new Swiper(slider, sliderParams[slider.dataset.slider]);
                //console.log(slider.swiper);
            }
        }
    }
});

/* const doctors_slider = document.querySelector(".doctors-slider");
if (doctors_slider) {
    window.addEventListener("DOMContentLoaded", function () {
        new Swiper(doctors_slider, sliderParams.doctors);
    });
}
const reviews_slider = document.querySelector(".reviews-slider");
if (doctors_slider) {
    window.addEventListener("DOMContentLoaded", function () {
        new Swiper(reviews_slider, sliderParams.reviews);
    });
} */
