import * as Popper from "@popperjs/core";
import { Dropdown, Collapse } from "bootstrap";
import Breakpoints from "@pluginjs/breakpoints";
import { placemarks } from "../footer2/footer2";

Breakpoints();
const xl = Breakpoints.get("lg-");
const searchFormToggler = document.querySelector(".search-toggler");
const referenceEl = document.querySelector(".header__inner");
const menu = document.querySelector(".header__menu");
const menuToggler = menu.querySelector(".navbar-collapse.collapse");
const menuTogglerButton = menu.querySelector(".header__menu [data-bs-toggle='collapse']");
let dr = Dropdown.getInstance(searchFormToggler);
let unlock = true;
let delay = 500;

//BodyLock
function body_lock_add(delay) {
    let body = document.querySelector("body");
    if (unlock) {
        let lock_padding = document.querySelectorAll("._scroll-compensate");
        for (const element of lock_padding) {
            const el = element;
            el.style.width = "100vw";
            el.style.paddingRight = window.innerWidth - document.querySelector(".document__wrapper").offsetWidth + "px";
        }
        body.style.paddingRight = window.innerWidth - document.querySelector(".document__wrapper").offsetWidth + "px";
        body.classList.add("_lock");
        unlock = false;
        setTimeout(function () {
            unlock = true;
        }, delay);
    }
    let backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    body.appendChild(backdrop);
}
function body_lock_remove(delay) {
    let body = document.querySelector("body");
    if (unlock) {
        let lock_padding = document.querySelectorAll("._scroll-compensate");
        setTimeout(() => {
            for (const element of lock_padding) {
                const el = element;
                el.style.paddingRight = "0px";
                el.style.width = "100%";
            }
            body.style.paddingRight = "0px";
            body.classList.remove("_lock");
        }, delay);
        unlock = false;
        setTimeout(function () {
            unlock = true;
        }, delay);
    }
    let backdrop = document.querySelector(".modal-backdrop.show");
    if (backdrop) {
        let parent = backdrop.parentNode;
        parent.removeChild(backdrop);
    }
}
function body_lock(delay) {
    let body = document.querySelector("body");
    if (body.classList.contains("_lock")) {
        body_lock_remove(delay);
    } else {
        body_lock_add(delay);
    }
}
if (menuToggler && Breakpoints.is(xl.name)) {
    menuToggler.addEventListener("hidden.bs.collapse", () => {
        if (unlock) {
            body_lock(delay);
        }
        //document.body.classList.remove("_menu-opened");
    });
    menuToggler.addEventListener("shown.bs.collapse", () => {
        body_lock(delay);
        //document.body.classList.add("_menu-opened");
    });
}



/** search */
if (Breakpoints.is("lg-")) {
    if (dr === null) {
        dr = new Dropdown(searchFormToggler, {
            reference: referenceEl,
            offset: [0, 0],
        });
    }
}
Breakpoints.from(xl.name, {
    enter: function () {
        if (dr !== null) {
            dr.dispose();
        }
    },
    leave: function () {
        if (dr === null) {
            dr = new Dropdown(searchFormToggler, {
                elementContext: "reference",
                reference: referenceEl,
                offset: [0, 0],
            });
        }
    }
});

window.addEventListener("load", function () {
    if (document.querySelector(".document__wrapper")) {
        setTimeout(function () {
            document.querySelector(".document__wrapper").classList.add("_loaded");
        }, 0);
    }
});
//=================
