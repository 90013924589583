import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video";
import Player from "@vimeo/player";

const items = document.querySelectorAll(".video-item");
if (items) {
    const arr = [...items];
    arr.forEach(v => {
        v.addEventListener("click", function () {
            console.log(v);
            console.log(v.dataset.frame);
            const iframe = document.getElementById(v.dataset.id);
            const iframePlayer = new Player(iframe);
            iframePlayer.play();
        });
    });
}
if (document.querySelector(".video-slider")) {
    let $lgSwiper = document.getElementById("video-gal");
    const swipervideo = new Swiper(".video-slider", {
        spaceBetween: 25,
        clickable: true,
        modules: [Navigation, Pagination],
        breakpoints: {
            0: {
                slidesPerView: "auto",
            },
            540: {
                slidesPerView: 2,
            },
            720: {
            },
            960: {
                slidesPerView: 3,
            },
            1140: {
                slidesPerView: 3,
            }
        },
        pagination: {
            el: ".video-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        },
        on: {
            init: function () {
                const lg = lightGallery($lgSwiper, {
                    plugins: [lgVideo],
                    licenseKey: "7EC452A9-0CFD441C-BD984C7C-17C8456E",
                    speed: 5000,
                });

                const videoslides = document.querySelectorAll(".video-slider__slide");
                let slides = [...videoslides];
                console.log(slides);

                // Before closing lightGallery, make sure swiper slide
                // is aligned with the lightGallery active slide
                $lgSwiper.addEventListener("lgBeforeClose", () => {
                    //$lgSwiper.slideTo(lg.index, 0);
                });
            },

        },
        navigation: {
            enabled: {
                720: {
                    enabled: true,
                }
            },
            nextEl: ".video-slider .slider__arrow-next",
            prevEl: ".video-slider .slider__arrow-prev",
        }
    });
    //console.log(video_slider);
}