//import LazyLoad from "vanilla-lazyload";
/*console.log("Banner2");

function logElementEvent(eventName, element) {
    console.log(
        Date.now(),
        eventName,
        element.getAttribute("data-bg-multi")
    );
}
const callback_enter = function (element) {
    logElementEvent("🔑 ENTERED", element);
};
const callback_exit = function (element) {
    logElementEvent("🚪 EXITED", element);
};
const callback_loading = function (element) {
    logElementEvent("⌚ LOADING", element);
};
const callback_applied = function (element) {
    logElementEvent("👍 APPLIED", element);
};
const callback_loaded = function (element) {
    logElementEvent("👍 LOADED", element);
};
const callback_error = function (element) {
    logElementEvent("💀 ERROR", element);
    element.style.backgroundImage =
        "url('https://via.placeholder.com/440x560/?text=Error+Placeholder')";
};
const callback_finish = function () {
    logElementEvent("✔️ FINISHED", document.documentElement);
};
const callback_cancel = function (element) {
    logElementEvent("🔥 CANCEL", element);
};
let lazyLoadInstance = new LazyLoad({
    use_native: true,
    elements_selector: ".banner2-lazy",
    container: document.querySelector(".banner2__wrapper"),
    // Assign the callbacks defined above
    callback_enter: callback_enter,
    callback_exit: callback_exit,
    callback_cancel: callback_cancel,
    callback_applied: callback_applied,
    callback_loading: callback_loading,
    callback_loaded: callback_loaded,
    callback_error: callback_error,
    callback_finish: callback_finish
});
console.log(lazyLoadInstance);*/