import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import Breakpoints from "@pluginjs/breakpoints";
import { getDirection } from "../../../js/modules/functions";


/* if (document.querySelector(".offers-slider")) {
    const offersSlider = new Swiper(".offers-slider", {
        spaceBetween: 25,
        direction: getDirection(),
        modules: [Navigation, Pagination],
        breakpoints: {
            0: {
                slidesPerView: "auto",
            },
            540: {
                slidesPerView: 2,
            },
            720: {
                slidesPerView: 3,
            },
            960: {
                slidesPerView: 4,
            },
            1140: {
                slidesPerView: 5,
            },
        },
        pagination: {
            el: ".offers-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        },
        navigation: {
            nextEl: ".offers-slider .slider__arrow-next",
            prevEl: ".offers-slider .slider__arrow-prev",
        }
    });
    console.info(offersSlider);
} */