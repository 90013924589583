import { Modal } from "bootstrap";
import Breakpoints from "@pluginjs/breakpoints";
import { wrap, setAttributes, removeAttributes, scrollToTargetAdjusted } from "../../../js/modules/functions";
import Inputmask from "inputmask";


Breakpoints();
let validPhone = false;
function initReviewModal() {
    const modalReviewContainer = document.querySelector(".reviews-block__new");
    if (modalReviewContainer) {
        /** togglers */
        const modalReviewTogglers = document.querySelectorAll("[data-modal=\"review\"]");
        let modalReviewContainerId = modalReviewContainer.getAttribute("id");
        modalReviewTogglers.forEach(el => {
            setAttributes(el, {
                "data-bs-toggle": "modal",
                "data-bs-target": "#" + modalReviewContainerId,
            });
        });
        /** modal container */
        modalReviewContainer.classList.add("modal", "fade");
        setAttributes(modalReviewContainer, {
            "tabindex": "-1",
            "aria-labelledby": "reviewFormCaption",
            "aria-hidden": "true",
            "data-bs-backdrop": "static",
            "style": "width: auto; height: auto;",
        });
        /** modal form */
        const modalForm = document.querySelector("#sendReview");
        modalForm.addEventListener("submit", event => {
            if (!(modalForm.checkValidity()) || !validPhone) {
                event.preventDefault();
                event.stopPropagation();
            }
            modalForm.classList.add("was-validated");
        }, false);
        /** phone inputmask */
        const consultationPhone = document.querySelector("#sendReview #inputPhone");
        if (consultationPhone) {
            Inputmask("+7 (999) 999-99-99", {
                //"placeholder": "+7(___)___-__-__",
                clearMaskOnLostFocus: false,
                "oncomplete": function () {
                    validPhone = true;
                    consultationPhone.classList.remove("is-invalid");
                },
                "onincomplete": function () {
                    validPhone = false;
                    consultationPhone.classList.add("is-invalid");
                },
            }).mask(consultationPhone);
        }

        /** wrappers */
        const modalDialog = document.createElement("div");
        modalDialog.classList.add("modal-dialog", "modal-dialog-centered", "modal-dialog-scrollable");
        const modalContent = document.createElement("div");
        modalContent.classList.add("modal-content");
        const modalBody = document.createElement("div");
        modalBody.classList.add("modal-body");
        wrap(modalForm, modalBody);
        wrap(modalBody, modalContent);
        wrap(modalContent, modalDialog);
        /** close btn */
        const closeBtn = document.createElement("button");
        setAttributes(closeBtn, {
            "aria-label": "Close",
            "data-bs-dismiss": "modal",
            "type": "button",
        });
        closeBtn.classList.add("btn", "btn-close", "d-lg-none");
        modalReviewContainer.appendChild(closeBtn);
        /** focus */
        const inputFocus = document.querySelector("#inputName");
        const modalReview = new Modal(modalReviewContainer, {});
        modalReviewContainer.addEventListener("shown.bs.modal", () => {
            inputFocus.focus();
        });
        console.log(modalReview);
    }
}
if (Breakpoints.is("md-")) {
    initReviewModal();
}
Breakpoints.on("md-", {
    enter: function () {
        initReviewModal();
        const modalReviewContainer = document.querySelector(".reviews-block__new");
        if (modalReviewContainer) {
            const modalReview = Modal.getInstance(modalReviewContainer);
            if (modalReview) modalReview.handleUpdate();
        }
    },
    leave: function () {
        const modalReviewContainer = document.querySelector(".reviews-block__new");
        if (modalReviewContainer) {
            const closeBtns = modalReviewContainer.querySelectorAll("[data-bs-dismiss=\"modal\"]");
            closeBtns.forEach(btn => {
                btn.remove();
            });
            const modalDialog = modalReviewContainer.querySelector(".modal-dialog");
            const modalBody = modalReviewContainer.querySelector(".modal-body");
            let content = modalBody.innerHTML;
            modalDialog.remove();
            const modalReview = Modal.getInstance(modalReviewContainer);
            if (modalReview) modalReview.dispose();
            modalReviewContainer.classList.remove("modal", "fade");
            removeAttributes(modalReviewContainer, {
                "tabindex": "-1",
                "aria-labelledby": "reviewFormCaption",
                "aria-hidden": "true",
                "data-bs-backdrop": "static",
                "style": "width: auto; height: auto;",
            });
            modalReviewContainer.innerHTML = content;
            console.log(modalReviewContainer);
            const modalReviewTogglers = document.querySelectorAll("[data-modal=\"review\"]");
            modalReviewTogglers.forEach(el => {
                removeAttributes(el, {
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#",
                });
            });
        }
    }
});