/* eslint-disable quotes */
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
//import * as Popper from "@popperjs/core";
import { Tab } from "bootstrap";
import Breakpoints from "@pluginjs/breakpoints";//
import LazyLoad from "vanilla-lazyload";
Breakpoints();

let newsSlider, articlesSlider;
//let slides;
const newsSwiperBreakpoints = Breakpoints.get('lg-');
//const slidersContainer = document.querySelector(".news-n-articles__block");

function getDirection() {
    const windowWidth = window.innerWidth;
    var direction = windowWidth <= 576 ? 'vertical' : 'horizontal';
    return direction;
}

function createSlider(name) {
    //console.log("." + name + "__slider");
    if (document.querySelector("." + name + "__slider1")) {
        const output = new Swiper("." + name + "__slider", {
            spaceBetween: 25,
            slideClass: name + "__slide",
            direction: getDirection(),
            modules: [Navigation, Pagination],
            //loading: "lazy",
            //lazy: true,
            breakpoints: {
                0: {
                    spaceBetween: 20,
                    slidesPerView: 3,
                },
                576: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                }
            }, on: {
                resize: function () {
                    this.changeDirection(getDirection());
                },
            },
            pagination: {
                el: "." + name + "-slider__dots",
                bulletClass: "slider__dot",
                bulletActiveClass: "slider__dot-active",
                clickable: true,
            },
            navigation: {
                enabled: {
                    576: {
                        enabled: false,
                    }
                },
                nextEl: "." + name + "__slider .slider__arrow-next",
                prevEl: "." + name + "__slider .slider__arrow-prev",
            }
        });
        //console.log(output);
        return output;
    }
}

//console.log('let initialize swiper on news & articles');

if (newsSwiperBreakpoints.isMatched()) {
    // let initialize swiper on news & articles
    //console.log('let initialize swiper on news & articles');
    //newsSlider = createSlider("news");
    //articlesSlider = createSlider("articles");
    //console.log(articlesSlider);
}
Breakpoints.on(newsSwiperBreakpoints.name, {
    enter: function () {
        //console.log(articlesSlider);
        /* if (slidersContainer) {
            slides = slidersContainer.querySelectorAll('.swiper-slide');
            for (const slide of slides) {
                let elem = document.createElement("div");
                elem.setAttribute("class", "swiper-lazy-preloader");
                slide.appendChild(elem);
            }
        } */
        /* const controls = slidersContainer.querySelectorAll("swiper-controls");
        for (const item of controls) {
            item.classList.remove("d-none");
        } */
        //newsSlider = createSlider("news");
        //articlesSlider = createSlider("articles");
        //console.info('enter ');
        //console.log(articlesSlider);
    },
    leave: function () {
        //console.info(articlesSlider);
        //if (newsSlider !== undefined) newsSlider.destroy(false, true);
        //if (articlesSlider !== undefined) articlesSlider.destroy(false, true);

        /* slides = slidersContainer.querySelectorAll('.swiper-slide');
        for (const slide of slides) {
            console.log(slide);
            const preloader = slide.querySelector(".swiper-lazy-preloader");
            if (preloader !== null) slide.removeChild(preloader);
        } */
        //console.info('leave ');
        //console.info(articlesSlider);
    }
});

const triggerTabList = document.querySelectorAll("#news-n-articles button[data-bs-toggle='tab']");
triggerTabList.forEach(triggerEl => {
    const tabTrigger = Tab.getOrCreateInstance(triggerEl);
    triggerEl.addEventListener("click", event => {
        event.preventDefault();
        tabTrigger.show();
    });
});

