import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";

if (document.querySelector(".banners-slider")) {
    const banners_slider = new Swiper(".banners-slider", {
        lazy: true,
        clickable: true,
        slidesPerView: 1,
        spaceBetween: 32,
        //watchOverflow: true,
        speed: 800,
        /*loop: true,
        autoplay: {
            delay: 5000,
        },*/
        modules: [Pagination, Autoplay],
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 1,
            },
            1200: {
                slidesPerView: 1,
            },
        },
        // Dots
        pagination: {
            el: ".banners-slider__dots",
            bulletClass: "slider__dot",
            bulletActiveClass: "slider__dot-active",
            clickable: true,
        }
    });

}