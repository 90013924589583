//import * as Popper from "@popperjs/core";
//import { Collapse, Dropdown, Button, Modal } from 'bootstrap';
import { Modal } from "bootstrap";
import Inputmask from "inputmask";

window.Modal = Modal;

//forms
const forms = document.getElementsByTagName("form");
if (forms) {
    for (const form of forms) {
        //phones
        let phones = form.querySelectorAll("._phone-field");
        if (phones) {
            for (const phone of phones) {
                new Inputmask({
                    mask: "[(8)|(+7)] 999-999-99-99",
                    alias: "phone"
                }).mask(phone);
            }
        }
        //emails
        let emails = form.querySelectorAll("._email-field");
        if (emails) {
            for (const email of emails) {
                new Inputmask("email").mask(email);
            }
        }
        //dates
        let dates = form.querySelectorAll("._date-field");
        if (dates) {
            for (const d of dates) {
                new Inputmask("99.99.9999", { "placeholder": "дд.мм.гггг" }).mask(d);
            }
        }
    }
}