import { sliderParams } from "../../../js/import/sliders";
import Swiper from "swiper";
const carousels = document.querySelectorAll(".swiper");
if (carousels.length) {
    carousels.forEach(carousel => {
        const name = carousel.dataset.params;
        if (name) {
            console.log({ carousel: carousel, params: sliderParams[name] });
            const sw = new Swiper(carousel, sliderParams[name]);
            sw.update();
        }
    });
}
