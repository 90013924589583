import { Datepicker } from "vanillajs-datepicker";
import ru from "vanillajs-datepicker/locales/ru";

Object.assign(Datepicker.locales, ru);
const today = new Date();

//const date = (today.getDay() == 0) ? new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" }).split(",")[0] : today.toLocaleString("ru-RU", { timeZone: "Europe/Moscow" }).split(",")[0];
const date = today.toLocaleString("ru-RU", { timeZone: "Europe/Moscow" }).split(",")[0];

const picker = document.querySelector("input._datepicker");
let form = null;
if (picker) {
    form = picker.closest(".mp2-form");
}

const datepickerOptions = {
    buttonClass: "btn",
    language: "ru",

    daysOfWeekHighlighted: [6],
    todayHighlight: true,
    autohide: true,
    minDate: date,
    //maxDate:
    title: "Дата записи"
};

if (picker && form) {
    const datepicker = new Datepicker(picker, datepickerOptions);
    if (datepicker) {
        datepicker.setDate(date, { clear: true, viewDate: today });
        picker.value = date;
        document.addEventListener("DOMContentLoaded", function () {
            form.dispatchEvent(new Event("change"));
        });
        picker.addEventListener("changeDate", function (e) {
            this.value = e.detail.date.toLocaleString("ru-RU", { timeZone: "Europe/Moscow" }).split(",")[0];
            form.dispatchEvent(new Event("change"));
        });
    }
    const formReset = document.querySelectorAll(".mp2-form ._reset-btn");
    if (formReset) {
        formReset.forEach(reset => {
            reset.addEventListener("click", function () {
                picker.value = date;
                datepicker.setDate(date, { clear: true, viewDate: today, forceRefresh: true });
                //console.log(picker);
                let baseUrl = window.location.href.split("?")[0];
                window.history.pushState("name", "", baseUrl);
                form.dispatchEvent(new Event("change"));
            });
        });
    }
}
export { datepickerOptions };