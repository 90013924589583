import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
export function createPartnersSlider() {
    if (document.querySelector(".partners-slider")) {
        new Swiper(".partners-slider", {
            spaceBetween: 45,
            autoplay: {
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
                delay: 3000
            },
            loop: true,
            loopPreventsSliding: false,
            modules: [Navigation, Pagination, Autoplay, Scrollbar],
            breakpoints: {
                340: {
                    slidesPerView: 1,
                },
                540: {
                    slidesPerView: 2,
                    spaceBetween: 22,
                },
                720: {
                    slidesPerView: 3,
                    spaceBetween: 22,
                },
                960: {
                    slidesPerView: 3,
                    spaceBetween: 22,
                },
                1140: {
                    slidesPerView: 4,
                },
            },
            pagination: {
                el: ".partners-slider__dots",
                bulletClass: "slider__dot",
                bulletActiveClass: "slider__dot-active",
                clickable: true,
            },
            navigation: {
                enabled: {
                    720: {
                        enabled: true,
                    }
                },
                nextEl: ".partners-slider__arrows.slider__arrow-next",
                prevEl: ".partners-slider__arrows.slider__arrow-prev",
            }
        });
    }
}
createPartnersSlider();