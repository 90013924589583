import LazyLoad from "vanilla-lazyload";
document.body.addEventListener("htmx:afterSwap", function (evt) {
    //console.log(evt.detail.target);
    const target = evt.detail.target;
    if (target) {
        let lazy = new LazyLoad({
            container: target
        });
        if (lazy) {
            lazy.update();
        }
    }
});
//тонкий баннер
const lazyThickBanner = new LazyLoad({
    container: document.querySelector(".banner__image")
});