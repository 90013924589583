import Inputmask from "inputmask";
const needConsultation = document.querySelector("#needConsultation");
if (needConsultation) {
    const needConsultationPhone = document.querySelector("#needConsultationPhone");
    Inputmask("+7 (999) 999-99-99", {
        "placeholder": "+7(___)___-__-__",
        clearMaskOnLostFocus: false,
    }).mask(needConsultationPhone);
    //console.log(needConsultationPhone);
    needConsultation.addEventListener("submit", event => {
        if (!(needConsultation.checkValidity())) {
            event.preventDefault();
            event.stopPropagation();
        }
        needConsultation.classList.add("was-validated");
    }, false);
}