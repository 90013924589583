import * as bootstrap from "bootstrap";
import { camelize } from "../../../js/modules/functions";

const modalZapis = document.getElementById("modal__make-appointment");
const form = document.querySelector("#modal__make-appointment form");
//console.log(form);
if (modalZapis) {
    modalZapis.addEventListener("show.bs.modal", event => {
        // Button that triggered the modal
        const button = event.relatedTarget;
        //const fields = ["branch","doctor","cal-id","doctor-id","doctor-adult","doctor-children","doctor-start-ages","special","special-id","date","time"];
        const fields = button.dataset;
        //console.log(fields);
        for (const key in fields) {
            if (key.search(/^mp2/) !== -1) {
                let camelFiled = camelize(key.replace(/^mp2/, "")).replace(/^\w/, c => c.toUpperCase());
                let destination = document.getElementById(`mp2${camelFiled}`);
                //console.log(destination);
                //console.log(camelFiled);
                if (destination) destination.value = fields[key].replace(/^\w/, c => c.toUpperCase());
            }
        }
    });
}
document.addEventListener("fetchit:before", (e) => {
    const { formData } = e.detail;
    const agreement = document.getElementById("mp2Agreement");
    if (agreement.checked) {
        formData.set("mp2Agreement", 1);
    } else {
        formData.set("mp2Agreement", 0);
    }
});

document.addEventListener("fetchit:after", (e) => {
    const { form, formData, response } = e.detail;
    //console.log(e);
    //console.log(form);
    const modal = document.querySelector("#modal__make-appointment");
    const modalInstance = bootstrap.Modal.getInstance(modal);
    if (!modalInstance || response.data.validated === false) {
        return;
    }
    modalInstance.hide();
    form.reset();
    const newmodal = bootstrap.Modal.getOrCreateInstance("#responseModal");
    if (newmodal) {
        const title = newmodal._element.querySelector(".modal-title");
        const body = newmodal._element.querySelector(".modal-body");
        if (title) title.innerHTML = response.message;
        if (body) body.innerHTML = (typeof response.data.html !== "undefined") ? response.data.html : "";
        newmodal.show();
    }
});
document.body.addEventListener("hidden.bs.modal", () => {
    const modals = document.querySelectorAll(".modal");
    if (modals) {
        [...modals].forEach(m => {
            let instance = bootstrap.Modal.getInstance(m);
            if (instance) {
                instance.hide();
            }
        });
    }
    if (document.body.classList.contains("modal-open")) document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    //console.log(backdrop);
    backdrop?.remove();
});