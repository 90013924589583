/*import Masonry from "masonry-layout";

 if (document.querySelector(".alphabet__list-wrapper")) {
    const msn = new Masonry(".alphabet__list-wrapper", { "percentPosition": true, "itemSelector": ".alphabet__list-item", "gutter": 10 });
    //console.log(msn);
} */
const filterDoctorBtns = document.querySelectorAll(".filter-doctor-btn");
if (filterDoctorBtns) {
    filterDoctorBtns.forEach(btn => {
        btn.addEventListener("change", (e) => {
            const t = e.target;
            console.log(t.dataset.doctorFor);
            const listDoctors = document.querySelectorAll("._list-doctors");
            listDoctors.forEach(el => {
                //^ TODO Ajax for doctors
                if (el.classList.contains("_" + t.dataset.doctorFor)) {
                    el.style.display = "block";
                } else {
                    el.style.display = "none";
                }
            });
            //if (e.target.classLi)
        });
    });
}