import SlimSelect from "slim-select";
function resetSelect(ss_list, ss_defaults) {
    ss_list.forEach((ss_obj, index) => {
        ss_obj.setSelected(ss_defaults[index]);
    });
    return true;
}

document.addEventListener("DOMContentLoaded", () => {
    const selects = document.querySelectorAll(".mp2-form select._select");
    if (selects) {
        selects.forEach(el => {
            if (typeof el.slim === "undefined") {
                new SlimSelect({
                    select: el,
                    settings: {
                        showSearch: (el.dataset.search == true) ? true : false,
                        searchText: "Нет результатов",
                        searchingText: "Поиск...",
                        searchPlaceholder: el.dataset.searchPlaceholder,
                        maxValuesShown: 5,
                        maxValuesMessage: "{number} выбрано",
                        allowDeselect: (typeof el.dataset.deselect != "undefined") ? false : true
                    },
                    events: {
                        beforeChange: (newVal, oldVal) => {
                            //console.log({ newVal: newVal, el: el });
                            if (newVal !== oldVal) return true; else return false;
                        }
                    }
                });
            }
        });
    }
});
const ss_list = [];
const ss_defaults = [];
const form = document.querySelector(".mp2-form");
if (form) {
    form.addEventListener("submit", function () {
        return false;
    });
    const formReset = document.querySelectorAll(".mp2-form ._reset-btn");
    if (formReset) {
        formReset.forEach(resetting => {
            resetting.addEventListener("click", function () {
                resetSelect(ss_list, ss_defaults);
            });
        });
    }

}
