const windowInnerWidth = document.documentElement.clientWidth;
//console.log(windowInnerWidth);
//const featuresCollapseParent = document.querySelector(".features__block-accordion.accordion");
const featuresCollapseItems = document.querySelectorAll(".features-block__item .accordion-collapse");

if (featuresCollapseItems) {

    [...featuresCollapseItems].forEach((element, index) => {
        if (windowInnerWidth > 1200) {
            if (!(element.classList.contains("show"))) {
                element.classList.add("show");
                if (!(element.parentElement.classList.contains("show"))) element.parentElement.classList.add("show");
            }
        } else {
            if (index == 0) {
                if (!(element.classList.contains("show"))) {
                    element.classList.add("show");
                    if (!(element.parentElement.classList.contains("show"))) element.parentElement.classList.add("show");
                }
            }
        }
    });
    featuresCollapseItems.forEach(element => {
        element.addEventListener("shown.bs.collapse", event => {
            console.log({ ev: "collapse shown...", el: event.target });
            let target = event.target.parentElement;
            if (target?.classList.contains("accordion-item")) {
                event.target.parentElement.classList.add("show");
            }
        });
        element.addEventListener("hidden.bs.collapse", event => {
            console.log({ ev: "collapse hidden...", el: event.target });
            let target = event.target.parentElement;
            if (target?.classList.contains("accordion-item")) {
                event.target.parentElement.classList.remove("show");
            }
        });
    });
}